<template>
	<b-modal ref="modal">
        <template slot="modal-header" slot-scope="{ close }">
            <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
            <h2 class="mx-auto my-auto">{{ $t('acte.modal_paillettes.title_paillettes') }}</h2>
        </template>
        <template slot="default">
            <div class="col-12">
            	{{ $t('acte.modal_paillettes.message_paillettes') }}
            </div>
        </template>
        <template slot="modal-footer" slot-scope="{ ok, cancel}" class="justify-content-center">
            <b-button  variant="primary" @click="confirm">
                <font-awesome-icon :icon="['far', 'save']" class="mr-1"/> {{ $t('global.valider') }}
            </b-button>
            <b-button @click="cancel()">
                <font-awesome-icon :icon="['fal', 'times']" class="mr-1"/> {{ $t('global.annuler') }}
            </b-button>
        </template>
    </b-modal>
</template>

<script type="text/javascript">

	export default {
		name: 'ModalAskPaillettes',
		props: ['callback_confirm'],
		methods: {
            openModal() {
				this.$refs.modal.show()
            },
            confirm() {
            	this.$refs.modal.hide()
            	this.callback_confirm()
            },
            cancel() {
            	this.$refs.modal.hide()
            }
        }
	}

</script>